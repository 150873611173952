import './styles.scss'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Image from '../../../../../assets/images/pbi_semfundo.png'
import loader from '../../../../../assets/images/newloader.gif'
import { ReportsContext } from 'contexts/reportsContext';
import AreaInput from '../Editar/components/AreaInput';
import api from 'services/api/api';
import Loader from '../../../../../assets/images/newloader.gif'
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function AtualizarRelatorio() {

    const dragRef = useRef();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const isLoadingRef = useRef(isLoading);

    const { requestReportsInfo, handleSumit, typeError, clearStates } = useContext(ReportsContext)

    const [isRdl, setIsRdl] = useState(false)
    const [step, setStep] = useState(1)
    const [file, setFile] = useState('')
    const [publishInWorkspace, setPublishInWorkspace] = useState(false)

    const [reports, setReports] = useState([])
    const [reportsPaginated, setReportsPaginated] = useState([])
    const reportsPaginatedRef = useRef(reportsPaginated);

    const [isSearchedPaged, setIsSearchedPaged] = useState(false)

    const [businessAreas, setBusinessAreas] = useState([])
    const [businessAreaSelected, setBusinessAreaSelected] = useState()
    const [businessAreaSelectedId, setBusinessAreaSelectedId] = useState('')

    const [workspace, setWorkspace] = useState([])
    const [selectedWorkspace, setSelectedWorkspace] = useState(null)

    const [isNotExistsReport, setIsNotExistsReport] = useState(false);
    const [isDifferentType, setIsDifferentType] = useState(false);

    const [isLoadingRefresh, setIsLoadingRefresh] = useState(false)
    const [isDatasetUpdate, setIsDatasetUpdate] = useState(false)

    function handleDragOver(e) {
        e.preventDefault()
    }

    function clearAllData() {
        setIsRdl(false)
        setFile('')
        setIsNotExistsReport(false)
        setIsDifferentType(false)
    }

    async function handleDrop(e) {
        e.preventDefault()
        clearAllData()
        let file = e.dataTransfer.files[0]
        console.log(file)
        if(file.name.includes('.pbix') || file.name.includes('.PBIX') || file.name.includes('.rdl') || file.name.includes('.RDL')) {
            setFile(file)
            setIsNotExistsReport(false)
            const {exists_embedded, exists_workspace} = selectExistsReport(file.name.split('.pbix')[0])
            const {exists_embedded_rdl, exists_workspace_rdl} = await selectExistsPaginated(file.name.split('.rdl')[0])
            if(exists_embedded || exists_embedded_rdl) {
                if(file.name.includes('.rdl') || file.name.includes('.RDL')) {
                        setIsRdl(true)
                        handleSumit(file, null, exists_workspace_rdl, true, true)
                } else {
                    const status = await getLastRefresh(exists_embedded, exists_workspace)
                    if(status) {
                        setIsDatasetUpdate(true)
                    } else {
                        handleSumit(file, null, exists_workspace)
                    }
                }
            } else {
                setIsNotExistsReport(true)
            }
        } else {
            setIsDifferentType(true)
        }
    }

    async function sendFile(file) {
        if(isSearchedPaged) {return}
        clearAllData()
        if(file.name.includes('.pbix') || file.name.includes('.PBIX') || file.name.includes('.rdl') || file.name.includes('.RDL')) {
            setFile(file)
            setIsNotExistsReport(false)
            const {exists_embedded, exists_workspace} = selectExistsReport(file.name.split('.pbix')[0])
            const {exists_embedded_rdl, exists_workspace_rdl} = await selectExistsPaginated(file.name.split('.rdl')[0])
            if(exists_embedded || exists_embedded_rdl) {
                if(file.name.includes('.rdl') || file.name.includes('.RDL')) {
                        setIsRdl(true)
                        handleSumit(file, null, exists_workspace_rdl, true, true)
                } else {
                    const status = await getLastRefresh(exists_embedded, exists_workspace)
                    if(status) {
                        setIsDatasetUpdate(true)
                    } else {
                        handleSumit(file, null, exists_workspace)
                    }
                }
            } else {
                setIsNotExistsReport(true)
            }
        } else {
            setIsDifferentType(true)
        }
    }

    function waitForLoading() {
        return new Promise(resolve => {
            const checkLoading = setInterval(() => {
                if (!isLoadingRef.current) {
                    clearInterval(checkLoading);
                    resolve();
                }
            }, 1000);
        });
    }

    function selectExistsReport(fileName) {
        const value = reports.find(item => item.nome === fileName)
        if(value && value != undefined) {
            return {exists_embedded: value.embedded_relatorio, exists_workspace: value.embedded_workspace}
        } else {
            return false
        }
    }

    async function selectExistsPaginated(fileName) {
        if(fileName.includes('.pbix')) {
            return false;
        } else {
            setIsSearchedPaged(true)
            await waitForLoading()
            await new Promise(resolve => setTimeout(resolve, 3000));
            setIsSearchedPaged(false)
            const value = reportsPaginatedRef.current.find(item => item.name === fileName)
            if(value && value != undefined) {
                return {exists_workspace_rdl: value.webUrl.split('/')[4], exists_embedded_rdl: value.webUrl.split('/')[6]}
            } else {
                return false
            }
        }
    }

    const getAll = useCallback(async () => {
        try {
            setIsLoading(true)
            const token = await getToken();
            const config = { headers: { 'Authorization': 'Bearer ' + token } };
            const promises = [getWorkspacesPB(config), getBusinessPowerBI(), handleRequestAllReports()]
            const [workspaces, actualBusiness] = await Promise.all(promises)
    
            const reportsByWorkspace = [];
    
            for (const workspace of workspaces) {
                const reports = await getReportsPB(workspace.id, config);
                if(reports.length > 0) {
                    reportsByWorkspace.push(...reports.filter(report => report.reportType === 'PaginatedReport'));
                }
            }
    
            setIsLoading(false)
            setReportsPaginated(reportsByWorkspace)
        } catch (error) {
            console.error(error.message);
        }
    }, []);

    const getToken = useCallback(async () => {
        try {
            const res = await requestReportsInfo('sync')
            return res.token;
        } catch (error) {
            return error
        }
    }, [])

    const getReportsPB = useCallback(async (groupId, config) => {
        try {
            const res = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports`, config)
            return res.data.value
        } catch (error) {
            return error    
        }
    }, [])

    async function handleRequestAllReports() {
        try {
            const res = await api.post("/reports")
            const filtred = res.data.relatorios.filter(item => item.status === 1)
            setReports(filtred)
        } catch (error) {
            return error.mensagem
        }
    }

    async function getWorkspacesPB(config) {
        try {
          const response = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups`, config)
          const transformedData = response.data.value.map(workspace => ({
            label: workspace.name,
            value: workspace.id
          }));
          setWorkspace(transformedData)
          return response.data.value.map(item => ({name: item.name, id: item.id}))
        } catch (error) {
          return error;
        }
    }

    async function getBusinessPowerBI() {
        try {
            const res = await api.post("/business");
            const areasNegocios = res.data.business.map((areaNegocio) => ({
                value: areaNegocio.id_area_negocio,
                label: areaNegocio.negocio,
                icon: areaNegocio.icon,
                cod_workspace: areaNegocio.cod_workspace
            }));
            setBusinessAreas(areasNegocios);
        } catch (error) {
            return error.message
        }
    }

    async function getLastRefresh(embedded_report, embedded_workspace) {
        try {
          setIsLoadingRefresh(true)
          const info = await api.post('/reportsInfo-v2', {"nome": 'Create Workspace'})
          const config = {
              headers: { 'Authorization': 'Bearer ' + info.data.token},
          };
          const first = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${embedded_workspace}/reports/${embedded_report}`, config)
          const second = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${embedded_workspace}/datasets/${first.data.datasetId}/refreshes?$top=1`, config)
          setIsLoadingRefresh(false)
          if(second.data.value.length > 0) {
              if(second.data.value[0].startTime && second.data.value[0].endTime) {
                return false
              } else {
                return true
              }
          } else {
            return false;
          }
        } catch (error) {
          return false
        }
      }

    useEffect(() => {getAll()},[])

    useEffect(() => { isLoadingRef.current = isLoading; }, [isLoading]);

    useEffect(() => { reportsPaginatedRef.current = reportsPaginated; }, [reportsPaginated]);

    return (
        <main className='publish-report-modal' onDragOver={handleDragOver} onDrop={handleDrop}>
            <div className={`publish-report-content ${step === 2 ? 'step-two' : ''}`}>
                <span className='close-update mdi mdi-close' onClick={() => clearStates()} />
                <form 
                    action=''
                    className={`publish-report-content-container ${step === 2 ? 'step-two' : ''}`}
                >
                    {step === 1 
                    ? 
                        <>
                            <input 
                                ref={dragRef}
                                hidden
                                type='file' 
                                accept=".pbix, .rdl"
                                className='input-drag-drop-field'
                                onChange={e => sendFile(e.target.files[0])}
                            />
                            <img src={Image} />
                            <div className='informations'>
                                <h1 className='title'>{t('publish.optionRefresh.title')}</h1>
                                <p>{t('publish.optionRefresh.description')}</p>
                            </div>
                            {!isSearchedPaged &&
                                <div className='btn-search-file' onClick={() => document.querySelector(".input-drag-drop-field").click()}>
                                    <p>{t('publish.optionRefresh.warn')}</p>
                                    <i className='mdi mdi-chevron-down' />
                                </div>
                            }
                            {isLoadingRefresh &&
                                <div className='send-file-loading'>
                                    <img src={loader} />
                                </div>
                            }
                            {
                                isSearchedPaged  && <img src={Loader} style={{height: 35, width: 35}} />
                            }
                            {
                                isDatasetUpdate &&
                                <div className='preview-send-file'>
                                    <i className='mdi mdi-database' style={{fontSize: 24}} />
                                    <p>{t('publish.optionRefresh.attExec')}</p>
                                </div>
                            }
                            {typeError && 
                                <div className='preview-send-file'>
                                    <p>{t('publish.optionNew.formatInsupport')}</p>
                                </div>
                            }
                            {
                                isNotExistsReport &&
                                <div className='preview-send-file'>
                                    <i className='mdi mdi-file-find' style={{fontSize: 18, marginBottom: 8}} />
                                    <p>{t('publish.optionRefresh.notExists')}</p>
                                </div>
                            }
                        </> 
                    :
                        <div className='publish-report-form-content'>
                            <AreaInput
                                label={t('form.workspace')} 
                                type='select'
                                inputProps={{
                                    placeholder: "",
                                    noOptionsMessage: () => t('form.notFound'),
                                    options: businessAreas,
                                    value: businessAreaSelected,
                                    onChange: (item) => {
                                        setBusinessAreaSelected(item)
                                        setBusinessAreaSelectedId(item.value)
                                    },
                                }}
                            />
                            {businessAreaSelected && businessAreaSelected.cod_workspace != '' && businessAreaSelected.cod_workspace != null &&
                                <AreaInput
                                    label={t('publish.optionNew.workspacePBI')} 
                                    type='switch'
                                    inputProps={{
                                        checked: publishInWorkspace, 
                                        onClick: () => {
                                            setPublishInWorkspace(!publishInWorkspace)}
                                    }}
                                />
                            } 
                            <button className='btn-send-report' onClick={() => handleSumit(file, businessAreaSelectedId, publishInWorkspace ? businessAreaSelected.cod_workspace : '')} disabled={!businessAreaSelected}>
                                {t('form.publish')}
                            </button>
                        </div>
                    }
                </form>
            </div>
        </main>
    )
}

export default withRouter(AtualizarRelatorio)