import './styles.scss'
import React from 'react'
import AIWriter from 'react-aiwriter'
import Markdown from 'markdown-to-jsx'

export const BotMessage = React.memo(({item, animated, scrollToBottom, handleAnimated}) => {
    return (
        <main className='bot-message-ai'>
            {item.activeAnimation ? 
                <AIWriter onFinish={() => scrollToBottom()}>
                    <Markdown 
                        options={{ 
                            wrapper: 'aside', 
                            forceWrapper: true, 
                            overrides: {
                                code: {
                                    // component: CustomComponent
                                },
                                table: {
                                    // component: CustomTable,
                                    props: {
                                        className: 'table-no-wrap'
                                    }
                                }
                            }
                        }}
                    >
                        {item.message.replace(/【\d+:\d+†source】/g, '')}
                    </Markdown>
                </AIWriter>
            :    
                <Markdown 
                    options={{ 
                        wrapper: 'aside', 
                        forceWrapper: true, 
                        overrides: {
                            code: {
                                // component: CustomComponent
                            },
                            table: {
                                // component: CustomTable,
                                props: {
                                    className: 'table-no-wrap'
                                }
                            }
                        }
                    }}
                >
                    {item.message.replace(/【\d+:\d+†source】/g, '')}
                </Markdown>
            }
        </main>
    )
})