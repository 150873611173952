/*
 * Autor: Jackson
 * Descricao: Arquivo responsavel pelas rotas do app
 */
import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { aceitaWebViewer, estaAutenticado, primeiroAcesso } from "../services/api/autenticacao";
import RenderConditional from "./components/renderConditional";
import { storage } from "utils";
import { requestSwitchPassowrd } from "utils/switchPassword";
import verificarContratoInadimplente from 'utils/contractInadimplente'


const PrivateRoute = (props) => {
  if (estaAutenticado()) {
    requestSwitchPassowrd();

    const userLogged = storage.get('@tijuca-usuario')
    const isAdmin = userLogged.adm === 1

    const minutos = 20;

    const milissegundos = minutos * 60 * 1000;

    if(isAdmin){
      setInterval(verificarContratoInadimplente, milissegundos)
    }

    if (primeiroAcesso() === 1 && props.location.pathname != '/login/primeiroAcesso') {
      return (<Redirect to="/login/primeiroAcesso" />);
    } else if (props.location.pathname === '/') {
      return (<Redirect to="/home" />);
    } else if (props.path === '*') {
      return (<Redirect to="/error-pages/error-404" />);
    }
    else {
      if(props.path.includes('/viewReport')) {
        let acessos = props.acessos || [];
        // console.log(acessos)
        let params = props.computedMatch.params
        if(acessos.find(item => item.embedded_relatorio === params.idReport) && acessos.find(item => item.embedded_workspace === params.idWorkSpace)) {
          return (<Route {...props} />);
        } else {
          return (<Redirect to="/error-pages/error-404" />);
        }        
      } else {
        return (<Route {...props} />);
      }
    }
  } else {
    if (props.location.pathname.includes('/privacy-policy')) {
      return (<Redirect to="/privacy-policy" />);
    }

    return (<Redirect to="/login" />);
  }
}

const WebViewerRoute = (props) => {
  if(aceitaWebViewer()) {
      return <Route {...props} />
  } else {
    if(estaAutenticado()) {
      return (<Redirect to="/home" />);
    } else {
      return (<Redirect to="/login" />)
    }
  }
}

const RedirectRoute = (props) => {
  if (!estaAutenticado()) {
    return (<Route {...props} />);
  } else {
    return (<Redirect to="/home" />);
  }
}

//////////////////////////////////////////////////////////////////////// area
const Dashboard = lazy(() => import("./components/dashboard/Dashboard"));
const Home = lazy(() => import("./pages/home"));
const Metrics = lazy(() => import("./pages/metrics"));
const Admin = lazy(() => import("./pages/admin/admin"));
// const Appearance = lazy(() => import("../app/pages/appearance"));
const Customization = lazy(() => import("../app/pages/customization"))
const Error404 = lazy(() => import("./components/error-pages/Error404"));
const Error500 = lazy(() => import("./components/error-pages/Error500"));
//////////////////////////////////////////////////////////////////////// TEMPLATE


//////////////////////////////////////////////////////////////////////// TIJUCA
// Usuários Crud
const Usuarios = lazy(() => import("./pages/admin/usuarios/Listar/index"));
const AddUsuarios = lazy(() => import("../app/pages/admin/usuarios/adicionar"));
const CriarUsuario = lazy(() => import("../app/pages/admin/novoUsuario/index"))
const EditarUsuario = lazy(() => import("../app/pages/admin/editarUsuario"));

// Areas de Negócios Crud
const AreasDeNegocio = lazy(() => import("./pages/admin/areasNegocios/Listar"));
const AddAreaNegocio = lazy(() => import("../app/pages/admin/areasNegocios/adicionar"));
const EditarAreaNegocio = lazy(() => import("../app/pages/admin/areasNegocios/editar"));

// Sub areas negócios
const SubAreasNegocio = lazy(() => import("./pages/admin/subAreasNegocio/Listar"));
const AddSubAreasNegocio = lazy(() => import("./pages/admin/subAreasNegocio/adicionar"))
const EditarSubAreaNegocio = lazy(() => import("./pages/admin/subAreasNegocio/editar"))

// Grupos de Usuários Crud
const usuariosGrupo = lazy(() => import("./pages/admin/usuariosGrupos/Listar/index"));
const AddUsuariosGrupos = lazy(() => import("./pages/admin/usuariosGrupos/adicionar"));
const EditarUsuariosGrupos = lazy(() => import("../app/pages/admin/usuariosGrupos/editar"));

// Logs auditoria
const logsAuditoria = lazy(() => import ("./pages/admin/logsAuditoria"))

// Relatórios Crud
const relatorios = lazy(() => import("./pages/admin/novosRelatorios/Listar/index"));
const addRelatorio = lazy(() => import("../app/pages/admin/relatorios/adicionar"));
const editarRelatorio = lazy(() => import("./pages/admin/novosRelatorios/Editar/index"));
const rlsRelatorio = lazy(() => import("./pages/admin/relatorios/rls"))

// Telas Crud
const paineis = lazy(() => import("./pages/admin/paineis/listar"));
const addPaineis = lazy(() => import("../app/pages/admin/paineis/adicionar"));
const editarPaineis = lazy(() => import("../app/pages/admin/paineis/editar"));

// Indicadores Crud
const indicadores = lazy(() => import("./pages/admin/indicadores/listar"));
const addIndicador = lazy(() => import("../app/pages/admin/indicadores/adicionar"));
const editarIndicador = lazy(() => import("../app/pages/admin/indicadores/editar"));

//Areas de Negocios - Pages
const relatorioPowerBIEmbedded = lazy(() => import("../app/pages/reports/powerbi.js"))

//New pages siginIn
const Signin = lazy(() => import("./pages/auth"));
const SigninWebViewer = lazy(() => import("./pages/siginWebViewer"))
const Retrieveuser = lazy(() => import("./pages/retrieveuser"));
const Recoverpassword = lazy(() => import("./pages/recoverpassword"));

// Autenticacao
const Login = lazy(() => import("./pages/login/Login"));
const LoginPrimeiroAcesso = lazy(() => import("./pages/login/LoginPrimeiroAcesso"));
const LoginAutenticacaoErp = lazy(() => import("./pages/login/LoginAutenticacaoErp"));

//Data Sources
const HandleDataSources = lazy(() => import("./pages/datasources/HandleDataSources"));
const ListDataSources = lazy(() => import("./pages/datasources/ListDataSources"));

//VIEW REPORT
const viewReport = lazy(() => import("./pages/admin/viewReport"));
const viewScorecard = lazy(() => import("./pages/admin/viewScorecard"));
const reportWebViewer = lazy(() => import("./pages/admin/reportWebViewer"));

// Azure
const AzureRegister = lazy(() => import("./pages/admin/azure/azureRegister"))

// Power BI Sync
const SyncBI = lazy(() => import("./pages/admin/syncBI"))

// Contratos Ativos
const ContractActive = lazy(() => import("./pages/admin/contractList"))
const NovoContrato = lazy(() => import("./pages/admin/contractCreate/formulario"))
const EditContract = lazy(() => import("./pages/admin/contractEdit/formulario"))

// SquadIA - Assistants CRUD
const SquadAI = lazy(() => import("./pages/admin/assistantsAI"));

//Perfil
const Profile = lazy(() => import("./pages/admin/perfil"))

// Jira
const Requests = lazy(() => import("./pages/admin/requests"))

// Politicas
const PrivacyPolicy = lazy(() => import("./pages/privacyPolicy"));


//////////////////////////////////////////////////////////////////////// TIJUCA


class AppRoutes extends Component {
  state = {
    status: false,
    relatorios: []
  }

  render() {

    let isAdmin = false;
    let isAdminUserGroups = false;
    let relatoriosAcesso = [];

    const isAuthenticated = estaAutenticado();

    const user = storage.get('@tijuca-usuario');

    if(!!user){
      isAdmin = user.adm == '1' ? true : false
      isAdminUserGroups = user.adm == '2' ? true : false
      relatoriosAcesso = user.relatorios ? user.relatorios : [];
    }

    const extrairRelatorios = (relatorios) => {
      let resultado = [];
      for (let key in relatorios) {
        if (Array.isArray(relatorios[key])) {
          resultado = resultado.concat(relatorios[key]);
        }
      }
      return resultado;
    }
    
    relatoriosAcesso = extrairRelatorios(relatoriosAcesso);

    if(isAdminUserGroups){
      return (
        <Suspense fallback={null}>
        <Switch>

          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute exact path="/metrics" component={Metrics} />
          <PrivateRoute exact path="/profile" component={Profile} />
          {/* Controle de fonte de dados */}

          <PrivateRoute path="/viewReport/:idReport/:idWorkSpace/:tipo/:paginado" component={viewReport} acessos={relatoriosAcesso} />
          <PrivateRoute path="/viewScorecard/:idReport/:idWorkSpace" component={viewScorecard} />
          <WebViewerRoute path="/reportWebViewer/:id_relatorio" component={reportWebViewer} /> 

          <PrivateRoute path="/pages/admin/relatorios" component={relatorios} />
          <PrivateRoute path="/formulario/addRelatorio" component={addRelatorio} />
          <PrivateRoute path="/formulario/editarRelatorio/:id_relatorio" component={editarRelatorio} />
          <PrivateRoute path="/formulario/rlsRelatorio/:id_relatorio" component={rlsRelatorio} />
          <PrivateRoute path="/formulario/logsAuditoria" component={logsAuditoria} />

        
   
          {/* <PrivateRoute path="/relatorios/conpag" component={relatorioPowerBIEmbedded} /> */}

          {this.state.status ?
            this.state.relatorios.map(relatorio => (
              <PrivateRoute path={`/${relatorio.rota}`} component={relatorioPowerBIEmbedded} />
            ))
            :
            null
          }

          <Route path="/error-pages/error-500" component={Error500} />
          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/login/retrieveuser" component={Retrieveuser} />
          <Route path="/login/recoverpassword" component={Recoverpassword} />
          <Route path="/login/webviewer/:id_relatorio" component={SigninWebViewer} />
          <Route path="/login" component={Signin} />
          <PrivateRoute path="*" />
        </Switch>
      </Suspense>
      )
    }
    
    if(!isAdmin){
      return (
        <Suspense fallback={null}>
        <Switch>

          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute exact path="/profile" component={Profile} />
          {/* Controle de fonte de dados */}

          <PrivateRoute path="/viewReport/:idReport/:idWorkSpace/:tipo/:paginado" component={viewReport} acessos={relatoriosAcesso} />
          <PrivateRoute path="/viewScorecard/:idReport/:idWorkSpace" component={viewScorecard} />

          <WebViewerRoute path="/reportWebViewer/:id_relatorio" component={reportWebViewer} />
   
          {this.state.status ?
            this.state.relatorios.map(relatorio => (
              <PrivateRoute path={`/${relatorio.rota}`} component={relatorioPowerBIEmbedded} />
            ))
            :
            null
          }

          <Route path="/error-pages/error-500" component={Error500} />
          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/login/retrieveuser" component={Retrieveuser} />
          <Route path="/login/recoverpassword" component={Recoverpassword} />
          <Route path="/login/webviewer/:id_relatorio" component={SigninWebViewer} />
          <Route path="/login" component={Signin} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <PrivateRoute path="*" />
        </Switch>
      </Suspense>
      )
    }
    
    return (
      <Suspense fallback={null}>
        <Switch>

          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute exact path="/metrics" component={Metrics} />
          <PrivateRoute exact path="/Admin" component={Admin} />
          {/* Gestao de aparencia */}
          <PrivateRoute exact path="/customization" component={Customization} />
          {/* Edicao do Usuario Logado */}
          <PrivateRoute exact path="/profile" component={Profile} />
          {/* Controle de fonte de dados */}

          <PrivateRoute exact path="/dataSource" component={ListDataSources} />
          <PrivateRoute exact path="/dataSource/handleNewDataSource" component={HandleDataSources} />
          <PrivateRoute exact path="/dataSource/handleEditDataSource/:id" component={HandleDataSources} />

          <PrivateRoute path="/dashboard/:login" component={LoginAutenticacaoErp} />
          <PrivateRoute path="/login/primeiroAcesso" component={LoginPrimeiroAcesso} />

          <PrivateRoute path="/pages/admin/Usuarios" component={Usuarios} />

          <PrivateRoute path="/formulario/addUsuario" component={CriarUsuario} />
          <PrivateRoute path="/formulario/editarUsuario/:id_usuario" component={EditarUsuario} />

          <PrivateRoute path="/formulario/addContract" component={NovoContrato} />
          <PrivateRoute path="/formulario/editContract/:idEmpresa" component={EditContract} />
          

          <PrivateRoute path="/pages/admin/areasDeNegocios" component={AreasDeNegocio} />
          <PrivateRoute path="/formulario/addAreaNegocio" component={AddAreaNegocio} />
          <PrivateRoute path="/formulario/editarAreaNegocio/:id" component={EditarAreaNegocio} />

          <PrivateRoute path="/pages/admin/subAreasNegocio" component={SubAreasNegocio} />
          <PrivateRoute path="/pages/admin/addSubAreasNegocio" component={AddSubAreasNegocio} />
          <PrivateRoute path="/pages/admin/editarSubAreasNegocio/:id" component={EditarSubAreaNegocio} />

          <PrivateRoute path="/pages/admin/grupoUsuarios" component={usuariosGrupo} />
          <PrivateRoute path="/formulario/addGruposUsuarios" component={AddUsuariosGrupos} />
          <PrivateRoute path="/formulario/editarGrupoUsuarios/:id" component={EditarUsuariosGrupos} />

          <PrivateRoute path="/formulario/logsAuditoria" component={logsAuditoria} />

          <PrivateRoute path="/pages/admin/relatorios" component={relatorios} />
          <PrivateRoute path="/formulario/addRelatorio" component={addRelatorio} />
          <PrivateRoute path="/formulario/editarRelatorio/:id_relatorio" component={editarRelatorio} />
          <PrivateRoute path="/formulario/rlsRelatorio/:id_relatorio" component={rlsRelatorio} />

          <PrivateRoute path="/pages/admin/paineis" component={paineis} />
          <PrivateRoute path="/formulario/addPainel" component={addPaineis} />
          <PrivateRoute path="/formulario/editarPainel/:id_painel" component={editarPaineis} />

          <PrivateRoute path="/pages/admin/indicadores" component={indicadores} />
          <PrivateRoute path="/formulario/addIndicador" component={addIndicador} />
          <PrivateRoute path="/formulario/editarIndicador/:id_indicador/:id_tela" component={editarIndicador} />

          <PrivateRoute path="/viewReport/:idReport/:idWorkSpace/:tipo/:paginado" component={viewReport} acessos={relatoriosAcesso} />
          <PrivateRoute path="/viewScorecard/:idReport/:idWorkSpace" component={viewScorecard} />

          <WebViewerRoute path="/reportWebViewer/:id_relatorio" component={reportWebViewer} />

          {/* Azure */}
          <PrivateRoute path="/pages/admin/azureRegister" component={AzureRegister} />
          
          {/* PowerBI Sync */}
          <PrivateRoute path="/pages/admin/sync" component={SyncBI} />

          <PrivateRoute path="/pages/admin/ContractActive" component={ContractActive} />
            {/* novo contrato */}
          <PrivateRoute path="/pages/admin/contractActive/formulario" component={NovoContrato} />

          <PrivateRoute path="/company/requests" component={Requests} />

          <PrivateRoute path="/pages/admin/squadai" component={SquadAI} />

          {/* <PrivateRoute path="/relatorios/conpag" component={relatorioPowerBIEmbedded} /> */}

          {this.state.status ?
            this.state.relatorios.map(relatorio => (
              <PrivateRoute path={`/${relatorio.rota}`} component={relatorioPowerBIEmbedded} />
            ))
            :
            null
          }

          <Route path="/error-pages/error-500" component={Error500} />
          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/login/retrieveuser" component={Retrieveuser} />
          <Route path="/login/recoverpassword" component={Recoverpassword} />
          <Route path="/login/webviewer/:id_relatorio" component={SigninWebViewer} />
          {isAuthenticated ? <Redirect from="/login" to="/home" /> :  <Route path="/login" component={Signin} />}

          <PrivateRoute path="*" />
          <RedirectRoute path="/" component={Signin} />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
