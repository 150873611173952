import './style.css';
import GroupItem from './group';
import { AuthContext } from 'contexts/appProvider';
import React, { useState, useEffect, useContext, useRef } from 'react';
// import powerbiDataAdm2 from '../../../assets/mock/routes/powerbiadm2.json'
import usePowerbiDataAdm2 from '../../../assets/mock/routes/powerbiadm2.js'
// import HomeData from '../../../assets/mock/routes/home.json'
import useHomeData from '../../../assets/mock/routes/home.js'
import useUsersData from '../../../assets/mock/routes/users.js'
// import ReportsData from '../../../assets/mock/routes/reports.json'
import useReportsData from '../../../assets/mock/routes/reports.js'
// import ScorecardData from '../../../assets/mock/routes/scorecards.json'
import useScorecardData from '../../../assets/mock/routes/scorecards.js'
import useAIData from '../../../assets/mock/routes/squadAI'
// import GruposData from '../../../assets/mock/routes/grupos.json'
import useGruposData from '../../../assets/mock/routes/grupos.js'
import usePowerBIData3 from '../../../assets/mock/routes/powerbiadm3.js'
import GroupMinimize from './groupMinimize';
import { deslogar, estaAutenticado } from 'services/api/autenticacao';
import { useHistory, useLocation } from 'react-router-dom';
import { storage } from 'utils';
import { Limiter } from './limiter';
import { version } from '../../../../package.json'
import { useTranslation } from 'react-i18next';

export default function Sidebar() {

    const { t } = useTranslation();
    
    const HomeData = useHomeData()
    const UsersData = useUsersData()
    const ReportsData = useReportsData()
    const ScorecardData = useScorecardData()
    const GruposData = useGruposData()
    const powerbiDataAdm2 = usePowerbiDataAdm2()
    const powerbiDataAdm3 = usePowerBIData3()
    const aiData = useAIData()

    let history = useHistory();
    let location = useLocation();
    let scrollRef = useRef(null);
    const [admin, setAdmin] = useState(false);
    const {colorDefault} = useContext(AuthContext);
    const [isBarMinimize, setIsBarMinimize] = useState(false);
    const [optionsActive, setOptionsActive] = useState("null");
    const [reports, setReports] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { userData } = useContext(AuthContext)
    
    let itemsWithValidLength
    let countReports;
    let discardedItemsCount;

    useEffect(() => {
        const user = storage.get('@tijuca-usuario')
        const reportss = Object.entries(user.relatorios)
        setAdmin(user.adm)
        setReports(reportss)
        setIsBarMinimize(document.body.classList.contains('sidebar-icon-only'))
    }, [document.body.classList.contains('sidebar-icon-only'), window.location.href, location, userData]);
    
    useEffect(() => {
    itemsWithValidLength = reports.filter((item) => item[0].split('-')[1].length !== 0);
    countReports = itemsWithValidLength.length;
    discardedItemsCount = reports.length - itemsWithValidLength.length;
    

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [userData]);
    
    function alterOptionsActiveValue(v) {
        if(v === optionsActive) {
            setOptionsActive("null");
        } else {
            setOptionsActive(v)
        }
        return
    }

    function exitAccount() {
        deslogar();
        history.push('/login')
    }
    
    if(!estaAutenticado()) return null;

    return (
        <nav className={!isBarMinimize && windowWidth > 991 ? 'container-sidebar' : 'container-sidebar-minize'} id='sidebar-geral' style={{background: colorDefault}} key={reports.length}>
            {
                !isBarMinimize && windowWidth > 991 ? 

                (
                    <div className='content-sidebar'>
                        <div className='items-content-sidebar'>
                            <GroupItem 
                                item={HomeData}
                                key={999}
                                first={admin === 0 && (itemsWithValidLength === 0) ? false : true}
                                last={false}
                            />
                            {
                                userData && userData.login !== 'admin' && admin === 1 && !window.location.host.includes('fieb') ? 
                                    <>  <GroupItem item={UsersData} key={991} />
                                        <GroupItem item={ReportsData} key={9933323} />
                                        <GroupItem item={ScorecardData} key={891893} />
                                        <GroupItem item={powerbiDataAdm3} key={1121893} />
                                        <GroupItem item={GruposData} key={992} last={countReports - discardedItemsCount === 0} />
                                    </>  
                                : userData && userData.login !== 'admin' && admin === 2 ?
                                    <>
                                        <GroupItem item={ReportsData} key={192311193} last={countReports - discardedItemsCount === 0} />
                                    </>
                                : userData && (window.location.host.includes('fieb') && (userData.adm == 1 && userData.login !== 'admin')) ? 
                                    <>  
                                        <GroupItem item={UsersData} key={991} />
                                        <GroupItem item={ReportsData} key={9312312393} />
                                        <GroupItem item={ScorecardData} key={891893} />
                                        <GroupItem item={GruposData} key={992} />
                                        <GroupItem item={powerbiDataAdm2} key={9912312313} last={countReports - discardedItemsCount === 0} />
                                    </> 
                                : userData && userData.login === 'admin' ? 
                                    <>
                                        <GroupItem item={UsersData} key={991} />
                                        <GroupItem item={ReportsData} key={9312312393} />
                                        <GroupItem item={ScorecardData} key={891893} />
                                        <GroupItem item={aiData} key={18884994223} />
                                        <GroupItem item={GruposData} key={992} />
                                        <GroupItem item={powerbiDataAdm2} key={9912312313} last={countReports - discardedItemsCount === 0} />
                                    </>
                                :
                                <></>
                            }
                            {
                                reports.length > 0 && admin !== 0 ?
                                    <>
                                        {reports.map((item, key) => {
                                                if(item[0].split('-')[1].length !== 0) {
                                                    return <GroupItem 
                                                                item={item} 
                                                                key={key} 
                                                                last={
                                                                    countReports - discardedItemsCount === key ||
                                                                    (discardedItemsCount === 0 && key === 0) ||
                                                                    (admin !== 0 && reports.length -1 === key)
                                                                }
                                                            /> 
                                                }
                                            })
                                        }
                                    </> 
                                : 

                                <>
                                    {reports.map((item, key) => {
                                        if (item[0].split('-')[1].length !== 0) {
                                            return (
                                                <GroupItem 
                                                    item={item} 
                                                    key={key} 
                                                    last={
                                                        countReports - discardedItemsCount === key ||
                                                        (discardedItemsCount === 0 && key === 0) ||
                                                        (admin === 0 && reports.length -1 === key)
                                                    } 
                                                /> 
                                            )}
                                        })
                                    }
                                </>
                            }
                        </div>
                        <div className='exit-button' onClick={exitAccount}>
                            <i className='mdi mdi-exit-to-app icon-exit' />
                            <div className='exit-button-info'>
                                <p className='word'>{t('account.exit')}</p>
                                <p className='version'>v{version ? version : '1.0.0'}</p>
                            </div>
                        </div>
                    </div>
                )

                :

                (
                    <div className='content-sidebar-minimize'>
                        <div className='items-content-sidebar-minimize' ref={scrollRef}>
                            <GroupMinimize 
                                item={HomeData} 
                                key={9001}
                                first={true}
                                click={alterOptionsActiveValue} 
                                isOpen={optionsActive === HomeData[0]} 
                                scrollRef={scrollRef}
                            />
                            {
                            userData && userData.login !== 'admin' && admin === 1 && !window.location.host.includes('fieb') ?
                            <>
                                <GroupMinimize 
                                    item={UsersData} 
                                    key={9002} 
                                    click={alterOptionsActiveValue} 
                                    isOpen={optionsActive === UsersData[0]} 
                                    scrollRef={scrollRef}
                                />
                                <GroupMinimize 
                                    item={ReportsData} 
                                    key={9003} 
                                    click={alterOptionsActiveValue} 
                                    isOpen={optionsActive === ReportsData[0]} 
                                    scrollRef={scrollRef}
                                />
                                <GroupMinimize 
                                    item={GruposData} 
                                    key={9004} 
                                    click={alterOptionsActiveValue} 
                                    isOpen={optionsActive === GruposData[0]} 
                                    scrollRef={scrollRef}
                                />
                                powerbiDataAdm3
                                <GroupMinimize 
                                    item={ScorecardData}
                                    key={9989999}
                                    click={alterOptionsActiveValue}
                                    isOpen={optionsActive === ScorecardData[0]}
                                    scrollRef={scrollRef}
                                />
                                <GroupMinimize 
                                    item={powerbiDataAdm3}
                                    key={565989999}
                                    click={alterOptionsActiveValue}
                                    isOpen={optionsActive === powerbiDataAdm3[0]}
                                    scrollRef={scrollRef}
                                />
                                <Limiter />
                            </>
                            : userData && userData.login !== 'admin' && admin === 2 ? 
                            <>
                                <GroupMinimize
                                    item={ReportsData} 
                                    key={9003}
                                    click={alterOptionsActiveValue}
                                    isOpen={optionsActive === ReportsData[0]}
                                    scrollRef={scrollRef}
                                />
                                <Limiter />
                            </>
                            : userData && (window.location.host.includes('fieb') && (userData.adm == 1 && userData.login !== 'admin')) ? 
                            <>
                                <GroupMinimize 
                                    item={UsersData} 
                                    key={939} 
                                    click={alterOptionsActiveValue} 
                                    isOpen={optionsActive === UsersData[0]} 
                                    scrollRef={scrollRef}
                                />
                                <GroupMinimize 
                                    item={ReportsData} 
                                    key={9002} 
                                    click={alterOptionsActiveValue} 
                                    isOpen={optionsActive === ReportsData[0]} 
                                    scrollRef={scrollRef}
                                />
                                <GroupMinimize 
                                    item={GruposData} 
                                    key={9003} 
                                    click={alterOptionsActiveValue} 
                                    isOpen={optionsActive === GruposData[0]} 
                                    scrollRef={scrollRef}
                                />
                                <GroupMinimize 
                                    item={powerbiDataAdm2} 
                                    key={9004} 
                                    click={alterOptionsActiveValue} 
                                    isOpen={optionsActive === powerbiDataAdm2[0]} 
                                    scrollRef={scrollRef}
                                />
                                <GroupMinimize 
                                    item={ScorecardData}
                                    key={998998799}
                                    click={alterOptionsActiveValue}
                                    isOpen={optionsActive === ScorecardData[0]}
                                    scrollRef={scrollRef}
                                />
                                <Limiter />
                            </>
                            : userData && userData.login === 'admin' ?
                            <>
                                <GroupMinimize 
                                    item={UsersData} 
                                    key={939} 
                                    click={alterOptionsActiveValue} 
                                    isOpen={optionsActive === UsersData[0]} 
                                    scrollRef={scrollRef}
                                />
                                <GroupMinimize 
                                    item={ReportsData} 
                                    key={9002} 
                                    click={alterOptionsActiveValue} 
                                    isOpen={optionsActive === ReportsData[0]} 
                                    scrollRef={scrollRef}
                                />
                                <GroupMinimize 
                                    item={GruposData} 
                                    key={9003} 
                                    click={alterOptionsActiveValue} 
                                    isOpen={optionsActive === GruposData[0]} 
                                    scrollRef={scrollRef}
                                />
                                <GroupMinimize 
                                    item={powerbiDataAdm2} 
                                    key={9004} 
                                    click={alterOptionsActiveValue} 
                                    isOpen={optionsActive === powerbiDataAdm2[0]} 
                                    scrollRef={scrollRef}
                                />
                                <GroupMinimize 
                                    item={ScorecardData}
                                    key={998998799}
                                    click={alterOptionsActiveValue}
                                    isOpen={optionsActive === ScorecardData[0]}
                                    scrollRef={scrollRef}
                                />
                                <GroupMinimize 
                                    item={aiData}
                                    key={94448799}
                                    click={alterOptionsActiveValue}
                                    isOpen={optionsActive === aiData[0]}
                                    scrollRef={scrollRef}
                                />
                                <Limiter />
                            </>
                            :
                            <>
                            </>
                        }
                        {
                            reports.length > 0 ?
                                <>
                                    {reports.map((item, key) => {
                                        if(item[0].split('-')[1].length !== 0) {
                                            return( 
                                                <GroupMinimize 
                                                    item={item}
                                                    key={key}
                                                    click={alterOptionsActiveValue}
                                                    isOpen={optionsActive === item[0]}
                                                    scrollRef={scrollRef}
                                                />
                                            )
                                        }
                                    })}
                                </> 
                            : 
                            <></>
                        }
                        </div>
                        <div className='exit-button-minimal' onClick={exitAccount}>
                            <i className='mdi mdi-exit-to-app icon-exit' />
                        </div>
                    </div>
                )

            }
        </nav>
    )
}