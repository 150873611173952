import './style.css'
import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from 'contexts/appProvider';
import ItemMinimize from '../itemMinimize';
import { useHistory, useLocation } from 'react-router-dom';
import * as Icon from '@icon-park/react';
import SubGroupMinimize from '../subGroupMinimize';

const GroupMinimize = forwardRef(function GroupMinimizeFunction({item, isOpen, click, scrollRef}) {

    let history = useHistory();
    let location = useLocation();
    const {colorDefault} = useContext(AuthContext);
    const [icone, setIcone] = useState('AllApplication');
    var numeric = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]

    const parentRef = useRef(null);
    const [top, setTop] = useState(0);

    // Função feita para analisar os icones da area de negocio, caso tenha algum problema ao encontrar o valor, o icone subgrupo será setado como AllApplication por padrão
    const verificarIcone = () => {
        if (item && Array.isArray(item) && item.length > 0) {
            if (item[0].length > 1 && !(item[0][1] === '-' || numeric.includes(item[0][1]))) {
                setIcone(item[0][1]);
            } else if (item[1] && Array.isArray(item[1]) && item[1].length > 0) {
                const firstItem = item[1][0];
                if (firstItem.icon && (firstItem.icon.includes('mdi mdi') || firstItem.icon.includes('mdi-'))) {
                    setIcone('AllApplication');
                } else if (firstItem.icon) {
                    setIcone(firstItem.icon);
                } else {
                    setIcone('AllApplication');
                }
            } else {
                setIcone('AllApplication');
            }
        } else {
            setIcone('AllApplication');
        }
    };

    function navigateFunction() {
        if(item.length > 1) {
            return click(item[0])
        } else {
            if(item[0][1] === 'Home') {
                history.push('/home')
            } else if(item[0][1] === 'SalesReport') {
                history.push('/pages/admin/relatorios')
            } else if(item[0][1] === 'PeopleSafe') {
                history.push('/pages/admin/usuarios')
            } else if(item[0][1] === 'Trophy') {
                history.push('/metrics')  
            } else if(item[0][1] === 'Robot') {
                history.push('/pages/admin/squadai')
            } else {
                history.push('/')
            }
        }
    }

    const isActive =
    (item.length > 1 && item[1].length > 0)
        ? item[1].some((path) => (location.pathname === `/viewReport/${path.embedded_relatorio}/${path.embedded_workspace}/${path.tipo}/${path.paginado}` || location.pathname === path.rota))
        : (
        (item[0][1] === 'Home' && location.pathname === '/home') ? true :
        (item[0][1] === 'SalesReport' && location.pathname === '/pages/admin/relatorios') ? true :
        (item[0][1] === 'PeopleSafe' && location.pathname === '/pages/admin/usuarios') ? true :
        false
        );
            

        useEffect(() => {
            const updateTop = () => {
                if (parentRef.current) {
                    const distanceToTop = parentRef.current.scrollTop + parentRef.current.getBoundingClientRect().top;
                    setTop(distanceToTop);
                }
            };
            
            const handleScroll = () => {
                updateTop();
            };
        
            if (parentRef.current) {
                parentRef.current.addEventListener('scroll', handleScroll);
            }
        
            if (scrollRef.current) {
                scrollRef.current.addEventListener('scroll', handleScroll);
            }
            
            updateTop();
            
            return () => {
                if (parentRef.current) {
                    parentRef.current.removeEventListener('scroll', handleScroll);
                }
                if (scrollRef.current) {
                    scrollRef.current.removeEventListener('scroll', handleScroll);
                }
            };
        }, []);        
        

    useEffect(() => {verificarIcone()}, [])

    const splitFirstDash = (str) => {
        const firstDashIndex = str.indexOf('-');
        if (firstDashIndex !== -1) {
            return str.slice(firstDashIndex + 1).trim();
        }
        return str;
    };

    return (
        <div 
            className={isActive ? 'group-minimize-container-active' : 'group-minimize-container'} 
            onClick={navigateFunction} 
            ref={parentRef}
            title={!(item[0][1] === '-' || numeric.includes(item[0][1])) ? item[0][0].split('-')[1] : item[0].split('-')[1]}
        >
            <div className={`group-minimize-icon`} key={item}>
                {React.createElement(Icon[icone] , { theme: "outline", size: 21, fill: "#eee" })} 
            </div>
            <p>{!(item[0][1] === '-' || numeric.includes(item[0][1])) ? item[0][0].split('-')[1] : splitFirstDash(item[0])}</p>
            
            {isOpen && item.length > 1 &&
                <div className='group-minimize-icon-list-items' style={{background: colorDefault, top: `${top}px`}}>
                    <i className='line'/>
                        {Array.from(new Set(item[1].filter(prop => prop.id_sub_grupo && Number(prop.id_sub_grupo) && Number(prop.status_sub_grupo) === 1).map(prop => prop.id_sub_grupo))).map((subGroupId, index, array) => {
                            const subItems = item[1].filter(subItem => subItem.id_sub_grupo === subGroupId && Number(subItem.id_sub_grupo) && Number(subItem.status_sub_grupo) === 1);
                            return (
                                <React.Fragment key={subGroupId}>
                                    {subItems.length > 0 && (
                                        <SubGroupMinimize
                                            item={subItems}
                                            group={!(item[0][1] === '-' || numeric.includes(item[0][1])) ? item[0][0] : item[0]}
                                            last={index === array.length - 1 && item[1].filter(prop => !(prop.id_sub_grupo && Number(prop.id_sub_grupo) && Number(prop.status_sub_grupo) === 1)).length === 0}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                        {item[1].filter(prop => !(prop.id_sub_grupo && Number(prop.id_sub_grupo) && Number(prop.status_sub_grupo) === 1)).map((prop, key, array) => (
                            <ItemMinimize
                                item={prop}
                                key={key}
                                group={!(item[0][1] === '-' || numeric.includes(item[0][1])) ? item[0][0] : item[0]}
                                last={key === array.length - 1}
                            />
                        ))}
                        
                        {/* {item[1].length > 0 &&
                            item[1].map((prop, key) => {
                                if(key === item[1].length -1) {
                                    return (
                                        <div className='group-minimize-list-items-part'>
                                            <ItemMinimize item={prop} key={key} last={true} />
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className='group-minimize-list-items-part'>
                                            <ItemMinimize item={prop} key={key} last={false} />
                                        </div>
                                    )
                                }
                            })
                        } */}
                </div>
            }

        </div>
    )
})

export default GroupMinimize