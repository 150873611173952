import './styles.scss'
import React, { useState } from 'react'
import { Loading } from '../loading'
import { BotMessage } from './bot-message'
import { UserMessage } from './user-message'

export const Messages = React.memo(({messages, scrollToBottom, isLoadingMessage}) => {

    const [animated, setAnimated] = useState(null)

    async function handleAnimatedResponse(message) {
        setAnimated(message)
        await new Promise(r => setTimeout(r, 2000));
        setAnimated(null)
    }

    return (
        <main className='messages-structure'>
            {messages.map((item, key) => {
                if(item.isBot) {
                    return <BotMessage 
                                key={key} 
                                item={item}
                                animated={item.message === animated} 
                                scrollToBottom={() => scrollToBottom()}
                                handleAnimated={
                                    (value) => handleAnimatedResponse(value)
                                }
                            />
                } else {
                    return <UserMessage key={key} item={item} />
                }
            })}
            {isLoadingMessage && <Loading />}
        </main>
    )
});