import './styles.scss'
import axios from 'axios'
import { AuthContext } from 'contexts/appProvider'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import api from 'services/api/api'
import { ReportsContext } from 'contexts/reportsContext'
import Logo from '../../../assets/images/squadia.png'
import { withRouter } from 'react-router-dom';
import { PulseLoader } from 'react-spinners'
import { Clear } from '@icon-park/react'
import { Tooltip } from '@mui/material'
import Swal from 'sweetalert2'
import { Messages } from './components/messages'
import { AssistantBallon } from './components/assistant-ballon'

function SquadIaDfeault() {

    const getUrl = window.location.href
    const resultsAreaRef = useRef(null);

    const { userData, idCurrentReport } = useContext(AuthContext);
    const { setActiveIA, requestReportsInfo } = useContext(ReportsContext);

    const [isLoading, setIsLoading] = useState(true)

    const [assistantId, setAssistantId] = useState(null)

    const [filtredAssistants, setFiltredAssistants] = useState([])

    const [words, setWords] = useState("");
    const [isAwaitResponseMessage, setIsAwaitResponseMessage] = useState(false)

    const [messages, setMessages] = useState([]);

    const getAssistants = useCallback(async (dataset) => {
        try {
            const res = await api.post('/assistants')
            if(res.data && res.data.status && res.data.assistants && res.data.assistants.length > 0) {
                
                const allAssistants = res.data.assistants
            
                const assistantsFiltred = allAssistants.filter(assistant => {

                    let userAccess = false;
                    let isAdmin = false;
    
                    if (assistant.usuarios) {
                        assistant.usuarios.forEach(usuario => {
                            if (usuario.id == userData.id_usuario) {
                                userAccess = true;
                                if (usuario.admin == "1") {
                                    isAdmin = true;
                                }
                            }
                        });
                    }
    
                    if (!userAccess && assistant.grupos) {
                        assistant.grupos.forEach(grupo => {
                            if (grupo.id == userData.grupoUsuario) {
                                userAccess = true;
                            }
                        });
                    }
    
                    if (userAccess) {
                        if (isAdmin) {
                            assistant.isAdmin = true;
                        }
                        return true;
                    }
    
                    return false;
                });
    
                if(dataset) {
                    const findDataset = assistantsFiltred.find((item) => item.dataset === dataset.datasetId)
                    if(findDataset) {
                        switchAssistant(findDataset.id_chatgpt)
                    }
                }

                setFiltredAssistants(assistantsFiltred);

            }
        } catch (error) {
            
        }
    }, [])

    const getDatasetReport = useCallback(async (groupId, reportId) => {
        try {
            const token = await requestReportsInfo('SquadAI')
            const config = { headers: { 'Authorization': 'Bearer ' + token.token } };
            const res = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}`, config)
            const report = res.data
            return {datasetId: report.datasetId, datasetWorkspaceId: report.datasetWorkspaceId}
        } catch (error) {
            return
        }
    }, [])

    const getAll = useCallback(async () => {
        try {
            setIsLoading(true)
            if(getUrl.includes('/viewReport')) {
                const report = findCurrentReport();
                const dataset = await getDatasetReport(report.embedded_workspace, report.embedded_relatorio)
                await getAssistants(dataset)
                // await new Promise(r => setTimeout(r, 3000));
                setIsLoading(false) 
            } else {
                await getAssistants()
                // await new Promise(r => setTimeout(r, 3000));
                setIsLoading(false)
            }
        } catch (error) {
            console.error(error);
            return error;
        }
    }, [])

    function findCurrentReport(){

        let reports = []
        if(userData){
          const telas = Object.values(userData.telas)
          const relatorios = Object.values(userData.relatorios)
      
          if(Array.isArray(telas)){
            if(telas.length){
              for (const tela of telas[0]) {
                reports.push(tela)
              }
            }
          }
      
          if(Array.isArray(relatorios)){
            if(relatorios.length){
              for (const relatorio of relatorios) {
                  if(Array.isArray(relatorio)){
                    if(relatorio.length){
                      reports.push(...relatorio)
                    }
                  }
                }
              }
            }
    
            const item = reports.find(report => report.embedded_relatorio == idCurrentReport)
    
            return item
        }
    
    }

    useEffect(() => {getAll()}, [])

    // == switch assistant 
    async function switchAssistant(id_assistant) {
        try {
            setIsLoading(true)
            setAssistantId(id_assistant)
            setMessages([])
            const res = await axios.get(`https://hooks.squadbi.app/chat/findChatActive/${userData.id_usuario}/${id_assistant}`)
            if(res.data && res.data.chat && res.data.chat.length > 0) {
                const oldMessage = res.data.chat
                setMessages([...oldMessage.map((item) => ({message: item.content, isBot: item.role != 'user', activeAnimation: false}))])
            } else {
                setMessages([])
            }
            setIsLoading(false)
            scrollToBottom()
        } catch (error) {
            return error;
        }
    }

    // == send prompt

    function sendPromptF(e) {
        e.preventDefault();
        if(!isAwaitResponseMessage) {
            sendPrompt()
        }

    }

    async function sendPrompt() {
        try {

            if(isAwaitResponseMessage || assistantId === null) {
                return
            }

            const message = words

            if(message.trim().length == 0)  {
                return
            }

            let stateMessages = [...messages];
            stateMessages.push({message, isBot: false, activeAnimation: true})
            setWords("")
            setMessages(stateMessages)
        
            await new Promise(resolve => setTimeout(resolve, 1000));
            setIsAwaitResponseMessage(true)

            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                }
            };
            
            const body = {
                "user_id": Number(userData.id_usuario),
                "assistant_id": assistantId,
                message
            }      
            
            const res = await axios.post('https://hooks.squadbi.app/chat', body, config)
            const botResponse = res.data.response.response

            stateMessages.push({message: botResponse, isBot: true, activeAnimation: true})

            setIsAwaitResponseMessage(false)
        } catch (error) {
            console.error(error)
        }
    }

    async function clearChat() {
        try {
            setIsLoading(true)
            const res = await axios.get(`https://hooks.squadbi.app/chat/clearChat/${userData.id_usuario}/${assistantId}`)
            if(res.status === 200) {
                setIsLoading(false)
                setMessages([])
            } else {
                setIsLoading(false)
                Swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "error",
                    title: "Ocorreu algum problema ao limpar conversa.",
                    showConfirmButton: false,
                    timer: 5000
                });
            }
        } catch (error) {
            setIsLoading(false)
            Swal.fire({
                toast: true,
                position: "top-end",
                icon: "error",
                title: "Ocorreu algum problema ao limpar conversa.",
                showConfirmButton: false,
                timer: 5000
            });
            return error;
        }
    }

    // == 

    const scrollToBottom = () => {
        if (resultsAreaRef.current) {
            resultsAreaRef.current.scrollTop = resultsAreaRef.current.scrollHeight;
        }
    };

    const scrollToTop = () => {
        if (resultsAreaRef.current) {
            resultsAreaRef.current.scrollTop = 0;
        }
    };
    
    useEffect(() => {
        scrollToBottom()
    }, [messages, isAwaitResponseMessage]);
    

    return (
        <div className="ia-default-container">
            <header className='ia-header'>
                <div className='right-header'>
                    <div className='header-squadia'>
                        <img src={Logo} />
                        <span className='info-ia'>
                            <p>Squad <strong>AI</strong></p>
                            <p className='preview-alert'>Preview</p>
                        </span>
                    </div>
                </div>
                <div className='left-header'>
                    {assistantId !== null && 
                        <div onClick={scrollToTop} className='header-long-item-click'>
                            <p>Mudar assistente</p>
                        </div>
                    }
                    {(assistantId !== null && messages.length > 1) && 
                        <Tooltip title="Limpar conversa">
                            <div onClick={clearChat} className='header-item-click'>
                                <Clear theme="outline" size="16" fill="#000000"/>
                            </div>
                        </Tooltip>
                    }
                    <Tooltip title="Fechar">
                        <div className='header-item-click'>
                            <i className='mdi mdi-close' onClick={() => setActiveIA(false)} />
                        </div>
                    </Tooltip>
                </div>
            </header>
            <main className='ia-content'>
                {isLoading 
                    ?
                        <div className='ia-content-loading'>
                            <div className='content-logo'>
                                <PulseLoader speedMultiplier={1} size={12} color='rgba(10, 44, 115, .5)' />
                            </div>
                        </div>
                    :     
                    <>  
                        <section className='results-area' ref={resultsAreaRef}>
                            <AssistantBallon
                                selected={assistantId}
                                onSelect={(v) => switchAssistant(v)}
                                filtredAssistants={filtredAssistants}
                            />
                            {
                                messages && messages.length > 0 && 
                                <Messages 
                                    messages={messages}
                                    scrollToBottom={() => scrollToBottom()}
                                    isLoadingMessage={isAwaitResponseMessage}
                                />
                            }
                        </section>  
                        <form className='typing-area' onSubmit={sendPromptF}>
                            <Tooltip title="Você precisa selecionar um assistente" followCursor open={assistantId === null}>
                                <div className='input-box'>
                                    <textarea
                                        disabled={assistantId === null} 
                                        className='typing-text-ia'
                                        placeholder='Pergunte ao Squad AI..'
                                        value={words}
                                        onChange={e => {
                                            if (e.target.value.length <= 500) {
                                                setWords(e.target.value);
                                            }
                                        }}
                                        onKeyDown={e => {
                                            if (e.key === 'Enter' && !e.shiftKey) {
                                                e.preventDefault()
                                                if(words.length > 0) {
                                                    sendPrompt()
                                                }
                                            }
                                        }}
                                    />
                                    <div className='words-send-btn'>
                                        <p>{`${words.length}/500`}</p>
                                        <i className='mdi mdi-send button-chat-msg' onClick={() => sendPrompt()} />
                                    </div>
                                </div>
                            </Tooltip>
                        </form>
                    </>
                }
            </main>
        </div>
    )
}

export default withRouter(SquadIaDfeault)