import { useTranslation } from 'react-i18next';

const useAIData = () => {
  const { t } = useTranslation();

  const data = [
    [
      `1-Squad AI (preview)`,
      "Robot"
    ]
  ];

  return data;
};

export default useAIData;