import { storage } from 'utils';
import { models } from 'powerbi-client';
import React, { Component } from 'react'
import api from '../../../services/api/api';
import { AuthContext } from 'contexts/appProvider';
import { PowerBIEmbed } from 'powerbi-client-react';
import SafeLock from '../../../assets/images/safelock.png'
export class RelatorioEmbedded extends Component {
  state = {
    workspace: undefined,
    relatorio: undefined,
    token: undefined,
    primeiraVez: true,
    vezesExecutado: 0
  }
  static contextType = AuthContext

  getURL = window.location.host

  async componentDidMount() {
    this.props.setPending(true)

    const { setIdCurrentReport } = this.context;
    setIdCurrentReport(this.props.relatorio)

    if(this.props.accessToken) {
      this.setState({ workspace: this.props.workspace, relatorio: this.props.relatorio, token: this.props.accessToken })
      return 
    }

    const objBody = {
      report: this.props.relatorio,
      workspace: this.props.workspace
    }
    
    if (this.props.rls_mail) {
      objBody.rls_mail = this.props.rls_mail;
    }
  
    if (this.props.rls_function) {
      objBody.rls_function = this.props.rls_function;
    }

    if(this.props.token) return this.setState({relatorio: this.props.relatorio, token: this.props.token})

    try {
      await api.post('/reportsView', objBody)
      .then(res => {
        const { workspace, relatorio, token} = res.data;
        this.setState({ workspace: workspace, relatorio: relatorio, token: token })
      })
      .catch(err => console.error(err.message))
    } catch (err) {
      this.props.error(err);
    }
  }

  async refreshReportToken() {
    try {
      const response = await api.post('/reportsViewRefresh', {report: this.props.relatorio, workspace: this.props.workspace, paginado: this.props.paginado})
      const token = response.data.token
      await window.report.setAccessToken(token)
      window.report.refresh()
    } catch (error) {
      api.post('/createRefreshTokenLog', {message: error.message || 'Problema ao renovar sessão', report: this.props.relatorio})
      console.error(error)
    }
  }

  async reportVisualError() {

    if (this.state.vezesExecutado >= 30) {
      return;
    }

    this.setState(prevState => ({
      vezesExecutado: prevState.vezesExecutado + 1
    }));

    this.setState({ primeiraVez: false });

    try {
      this.refreshReportToken();
      await api.post('/createRefreshTokenLog', { message: 'Gerando sessão após erro.', report: this.props.relatorio });
    } catch (error) {
      return error;
    }
  }

  async addBookmarkInCache() {
    
    let history = storage.get('@user_history') || [];
      
    const existingIndex = history.findIndex((historyItem) => historyItem.embedded_relatorio === this.props.relatorio);
        
    if (existingIndex !== -1) {
      const item = history[existingIndex];
        // if(token) {
        //   item.date_token = new Date();
        //   item.acess_token = token;
        //   item.tokenPowerBiApi = tokenPowerBiApi;
        // } else {
        //   item.history = new Date();
        // }
      const bookmark = await window.report.bookmarksManager.capture({allPages: true, personalizeVisuals: true});
      item.last_bookmark_state = bookmark.state;
      item.history = new Date();
      history[existingIndex] = item;
        
      storage.set('@user_history', history);    
    }
  }

  render() {

    const { findFilters } = this.context;

    return (
      <div style={{position: this.props.rlsBlock && 'relative', flex: 1}}>
        {this.props.rlsBlock && <div style={{
            backgroundColor: 'rgba(25,25,25,.1)', 
            backdropFilter: 'blur(100px)', 
            position: 'absolute', 
            height: '100%', 
            width: '100%', 
            display: 'flex',
            flexDirection: 'column', 
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <img src={SafeLock} style={{height: 64, marginBottom: 15}} />
          <p style={{color: "#444", fontWeight: 'bold', fontSize: '130%'}}>
            Informações do relatório bloqueadas
          </p>
          <p style={{color: "#222", fontSize: '100%'}}>Você não está atribuido a uma função RLS nesse relatório</p>
        </div>}
        <PowerBIEmbed
          embedConfig={{
            bookmark: {state: this.props.hastBookmark && this.props.hastBookmark},
            type: 'report',
            id: this.props.relatorio,
            // embedUrl: 'https://app.powerbi.com/reportEmbed?actionBarEnabled=true',
            embedUrl: 'https://app.powerbi.com/reportEmbed',
            accessToken: !this.props.rlsBlock && this.state.token,
            tokenType: this.props.paginado == 1 ? models.TokenType.Aad : models.TokenType.Embed,
            permissions: models.Permissions.All,
            settings: {
              // persistentFiltersEnabled: true,
              // bars: {
              //   actionBar: {
              //     visible: true
              // },
              //     statusBar: {
              //         visible: true
              //     }
              // },
              // visualSettings: {
              //   visualHeaders: [
              //       {
              //           settings: {
              //             visible: true
              //           }
              //       }
              //   ]
              // },
              // layoutType: models.LayoutType.Custom,
              customLayout: {
                displayOption: models.DisplayOption.FitToWidth,
                marginTop: 0
              },
              panes: {
                bookmarks: {
                  visible: false
                },
                fields: {
                  expanded: false
                },
                filters: {
                  expanded: false,
                  visible: this.props.filters === false ? false : true
                },
                pageNavigation: {
                  visible: this.props.showLeftNavigation,
                  position: 0,
                },
                selection: {
                  visible: true
              },
              syncSlicers: {
                  visible: true
              },
              visualizations: {
                  expanded: false
              },
              //   pageNavigation: {
              //     visible: true,
              //     position: models.PageNavigationPosition.Left,
              // },
              filters: {
                expanded: false,
                visible: this.getURL.includes('cerbras') ? false : this.props.filters === false ? false : true
              }
              },
            }
          }}
          cssClassName={`powerbi-content ${this.props.pending ? 'hidden' : ''}`}
          eventHandlers={
            
            // this.props.setPending(false)
            
            new Map([
              ['loaded', ()  => {  
                this.props.setPending(false)
                this.addBookmarkInCache()
              }],
              ['rendered', () => { 
                this.props.setPending(false)
                this.addBookmarkInCache()
              }],
              ['error', (event) => {
                this.reportVisualError()
              }],
              ['visualClicked', () => {
                this.addBookmarkInCache()
                findFilters(this.props.relatorio)
              }],
			        ['selectionChanged', (event) => {
                this.addBookmarkInCache()
                findFilters(this.props.relatorio)
              }],
              ['pageChanged', (event) => {
                this.addBookmarkInCache()
                findFilters(this.props.relatorio)
              }],
              ['visualClicked', () => {
                this.addBookmarkInCache()
                findFilters(this.props.relatorio)
              }]
            ])
          }
          // Disponibiliza o embed globalmente
          getEmbeddedComponent = {
            (embeddedReport) => {
                window.report = embeddedReport;
            }
          }
        />
      </div>
    )
  }
}

export default RelatorioEmbedded
