import './style.css'
import History from './history'
import { storage } from 'utils'
import NotFound from './notFound'
import ListItems from './listItems'
import { Search } from '@icon-park/react'
import React, { useEffect, useState } from 'react'
import Grupos from '../../../assets/mock/routes/gruposSearch.json'
import PowerBI from '../../../assets/mock/routes/powerbi.json'
import PowerBIadm2 from '../../../assets/mock/routes/powerbiadm2.json'

export default function SearchBar({placeholder, data, thisIsGlobalSearch}) {

    const [wordValue, setWordValue] = useState("")
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [itemsForRendering, setItemsForRendering] = useState([])
    const [itemsFilteredForRendering, setItemsFilteredForRendering] = useState([])
    const [historyItems, setHistoryItems]= useState([])

    const [reload, setReload] = useState(false)

    function handleReloadInfo() {
        setReload(!reload)
    }

    // transformar os dados mock das rotas admin
    function transformMockRoutes(...allDatas) {
        const data = [];
      
        allDatas.forEach((dados) => {
          if (Array.isArray(dados) && dados.length === 2) {
            const [header, items] = dados;
            data.push(
              ...items.map((item) => ({
                id: item.id || null,
                nome: item.nome || null,
                embedded_relatorio: null,
                embedded_workspace: null,
                paginado: null,
                tipo: null,
                rota: item.rota || null,
                icon: header[1] || null,
                grupo_negocio: header[0] || null,
                history: item.history || null
              }))
            );
          }
        });
      
        return data;
    }

    //tratar dados
    function treatData(items) {
        
        let mockRoutes = [];

        if (thisIsGlobalSearch) {
            const admin = items && items.adm && items.adm
            if(items && items.login && items.login !== 'admin' && admin === 1) {
                mockRoutes = transformMockRoutes(Grupos, PowerBI);
            } else if(items && items.login && items.login !== 'admin' && admin === 2) {
                mockRoutes = transformMockRoutes(PowerBI);
            } else if(items && items.login && items.login === 'admin'){
                mockRoutes = transformMockRoutes(Grupos, PowerBI, PowerBIadm2)
            }else {
                mockRoutes = transformMockRoutes()
            }

            const newData = items && Object.keys(items.relatorios).flatMap((grupoNegocio) => {
                return items.relatorios[grupoNegocio].map((item) => {
                    const newItem = {
                        id: item.id,
                        nome: item.nome,
                        embedded_relatorio: item.embedded_relatorio,
                        embedded_workspace: item.embedded_workspace,
                        paginado: item.paginado,
                        tipo: item.tipo,
                        rota: null,
                        icon: item.icon,
                        grupo_negocio: grupoNegocio,
                        history: null
                    };
                    return newItem;
                });
            });
        
            var finalResult = items && newData.concat(mockRoutes)   

            const user_history_items = (storage.get('@user_history') || []).filter(item => item.id && item.nome);

            // Abaixo é feito a validação para saber o item foi modificado --

            const filteredHistory = user_history_items.filter(historyItem => {
                if (historyItem.id && historyItem.embedded_relatorio) {
                    return finalResult.some(resultItem => {
                        return resultItem.id === historyItem.id && resultItem.embedded_relatorio === historyItem.embedded_relatorio;
                    });
                } else {
                    return false;
                }
            });

            setHistoryItems(filteredHistory)

            return finalResult;
        }
        return items;
    }

    useEffect(() => {setItemsForRendering(treatData(data))},[reload])

    // função para retirar os acentos
    function removeAccents(str) {
        return str
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
    }

    // função para criar o filtro
    function handleFilter(e) {
        setWordValue(e.target.value)
        const searchWord = removeAccents(e.target.value.toLowerCase());
        const newFilter = itemsForRendering.filter((value) => {
          if (searchWord.length !== 0) {
            const itemNameWithoutAccents = removeAccents(value.nome.toLowerCase());
            return itemNameWithoutAccents.includes(searchWord);
          }
          return false;
        });
        setItemsFilteredForRendering(newFilter);
    }

    return (
        <div>
            <div className='search-bar-container'>
                <div className='search-bar-inputs'>
                    <input 
                        type='text' 
                        placeholder={placeholder} 
                        value={wordValue}
                        onChange={(e) => handleFilter(e)}
                        onFocus={() => setIsOverlayVisible(true)}
                        onBlur={() => setTimeout(() => setIsOverlayVisible(false), 200)}
                        id="search"
                        name="search"
                        autoComplete='off'
                        className='input-search-bar'
                    />
                    <label className='search-bar-icon' for="search">
                        <Search theme="outline" size="16" fill="#777"/>
                    </label>
                </div>
                {isOverlayVisible && (
                    <div className='search-bar-results'>
                        {wordValue.length === 0 ? (
                            historyItems.length !== 0 ? 
                                <History historyItems={historyItems} reload={handleReloadInfo} />
                            :
                                <NotFound />
                        ) : (
                            itemsFilteredForRendering.length !== 0 ?
                                <ListItems itemsFilteredForRendering={itemsFilteredForRendering} reload={handleReloadInfo} />
                            :
                                <NotFound />
                        )}
                    </div>
                )}
            </div>
            {isOverlayVisible && <div className='overlay-search-bar'></div>}
        </div>
    )
}