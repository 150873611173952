import React, { createContext, useContext, useState } from "react";
import PublicarRelatorio from '../app/pages/admin/novosRelatorios/Publicar'
import AtualizarRelatorio from '../app/pages/admin/novosRelatorios/AtualizarRelatorio'
import axios from "axios";
import api from "services/api/api";
import { AuthContext } from "./appProvider";
import Swal from "sweetalert2";
import { ShippingCard } from "app/components";
import ShippingExportCard from "app/components/ShippingExportCard";
import ShippingUpdateCard from "app/components/ShippingUpdateCard";
import ModalSync from "app/components/modalSync";
import ShippingAssistantCard from "app/components/ShippingAssistantCard";
import ModalSquadAI from "app/components/modalSquadIA";

export const ReportsContext = createContext();

export const ReportsContextProvider = ({ children }) => {

    const { onRefreshUserPermissionsAccess } = useContext(AuthContext)

    const [typeSubmit, setTypeSubmit] = useState('none') // none, report, paginated, update
    const [statusSubmit, setStatusSubmit] = useState('indle') // status: 1. "indle" -- 2."file selection" -- 3."sending" -- 4."waiting for user" 
    const [exportingReport, setExportingReport] = useState('indle'); // status: 1. "indle" -- 2."awaiting server" -- 3. "waiting for user"  
    const [downloandReport, setDowloandReport] = useState('indle') // status: 1. "indle" -- 2."awaiting server" -- 3. "waiting for user"
    const [updatingReport, setUpdatingReport] = useState('indle') // status: 1. "indle" -- 2. "awaiting server" -- 3. "success" / "error"
    const [syncPowerBI, setSyncPowerBI] = useState('indle') // status: 1. "indle" -- 2. "open"

    const [activeIA, setActiveIA] = useState(false)

    const [awaitAssistant, setAwaitAssistant] = useState(false)
    const [awaitAssistantStatus, setAwaitAssistantStatus] = useState(0)
    
    const [pbix, setPbix] = useState(null);
    const [blobFile, setBlobFile] = useState([])
    const [blobPbix, setBlobPbix] = useState(null)
    
    const [typeError, setTypeError] = useState(false)
    const [conflictError, setConflictError] = useState(false)
    const [dualUpdateError, setDualUpdateError] = useState(false)

    const [reportId, setReportId] = useState('')
    const [reportData, setReportData] = useState(null)
    const [updateReportID, setUpdateReportID] = useState(null)

    function clearStates() {
        setStatusSubmit("indle")
        setPbix(null)
        setTypeError(false)
        setReportData(null)
        setConflictError(false)
        setReportId('')
        setTypeSubmit('none')
    }

    function uploadReport(type) {
        setStatusSubmit('file selection')
        setTypeSubmit(type)
    }

    function changeForAwaitForUser() {
        setStatusSubmit('waiting for user')
        
        setTimeout(function() {
            clearStates()
        }, 8000); 
    }

    // submit function =========================
    async function handleSumit(file, businessArea, publishInWorkspace, isRDL = false, isRDLUpdate = false) {
        const isReport = file.name.includes('.pbix') || file.name.includes('.PBIX') || file.name.includes('.rdl') || file.name.includes('.RDL')
        if(isReport) {
            try {
                setPbix(file)
                setTypeError(false)
                setStatusSubmit('sending')


                const name = file.name.split('.pbix')[0]

                const info = await requestReportsInfo(name)
                setReportId(info.id_relatorio)

                if(!info.status) {
                    Swal.fire({
                        title: "<strong>Não foi possível publicar</strong>",
                        icon: "info",
                        html:
                          "Falha ao publicar esse relatório<br> " +
                          "Valide a integridade do arquivo .PBIX",
                        confirmButtonText:"<i class='fa fa-thumbs-up'></i> Fechar",
                        showCloseButton: true,
                        showCancelButton: false,
                        focusConfirm: false
                    })
                    await new Promise(resolve => setTimeout(resolve, 5000));
                    clearStates()
                    return
                }


                const rdlWrite =  isRDLUpdate ? 'Overwrite' : 'Abort'
                //publishInWorkspace.trim() !== '' ? publishInWorkspace : info.workspace
                const reportFabric = await publishInFabric(publishInWorkspace.trim() !== '' ? publishInWorkspace : info.workspace, info.token, isRDL ? rdlWrite : info.nameConflict, file, file.name)

                const reportId = reportFabric.id
               
                if(reportId.message) {
                    Swal.fire({
                        title: "<strong>Não foi possível publicar</strong>",
                        icon: "info",
                        html:
                          "Falha ao publicar esse relatório.<br> " +
                          "Valide a integridade do arquivo .PBIX",
                        confirmButtonText:"<i class='fa fa-thumbs-up'></i> Fechar",
                        showCloseButton: true,
                        showCancelButton: false,
                        focusConfirm: false
                    })
                    await new Promise(resolve => setTimeout(resolve, 5000));
                    clearStates()
                    return
                }

                // publishInWorkspace.trim() !== '' ? publishInWorkspace : info.workspace,
                const datasetId = await requestDataSetID(publishInWorkspace.trim() !== '' ? publishInWorkspace : info.workspace, reportId, info.token);

                if(isRDL) {
                    if(reportId) {
                        return changeForAwaitForUser()
                    } else {
                        Swal.fire({
                            title: "<strong>Não foi possível publicar</strong>",
                            icon: "info",
                            html:
                              "Falha ao publicar esse relatório...<br> " +
                              "Valide a integridade do arquivo .PBIX",
                            confirmButtonText:"<i class='fa fa-thumbs-up'></i> Fechar",
                            showCloseButton: true,
                            showCancelButton: false,
                            focusConfirm: false
                        })
                        await new Promise(resolve => setTimeout(resolve, 5000));
                        clearStates()
                    }
                }

                if(info.nameConflict !== "Overwrite" && file) {
                    //publishInWorkspace.trim() !== '' ? publishInWorkspace : info.workspace
                    const response = await handleSendToSquadBI(name, datasetId.reportId, datasetId.datasetId, publishInWorkspace.trim() !== '' ? publishInWorkspace : info.workspace, businessArea, reportFabric)
                    
                    const reportData = await requestReport(response.id_relatorio)
                    setReportId(response.id_relatorio)

                    const saveReportSquadbi = await saveReport(file, businessArea, response.id_relatorio);

                    if(!saveReportSquadbi.status) {
                        Swal.fire({
                            title: "<strong>Ocorreu uma falha ao publicar</strong>",
                            icon: "info",
                            html:
                              `${saveReportSquadbi.ERR}`,
                            confirmButtonText:"<i class='fa fa-thumbs-up'></i> Fechar",
                            showCloseButton: true,
                            showCancelButton: false,
                            focusConfirm: false
                        })
                        await new Promise(resolve => setTimeout(resolve, 5000));
                        clearStates()
                        return
                    }
                    
                }
                if(info.nameConflict === "Overwrite" && file) {
                    const updateReportSquadbi = await updateReport(file, info.workspaceId, info.id_relatorio);
                    if(!updateReportSquadbi.status) {
                        Swal.fire({
                            title: "<strong>Não foi possível atualizar</strong>",
                            icon: "info",
                            html:
                              "Falha ao atualizar esse relatório<br> " +
                              `${updateReportSquadbi.ERR}`,
                            confirmButtonText:"<i class='fa fa-thumbs-up'></i> Fechar",
                            showCloseButton: true,
                            showCancelButton: false,
                            focusConfirm: false
                        })
                        await new Promise(resolve => setTimeout(resolve, 5000));
                        clearStates()
                        return
                    }



                    const res = await api.post('/overwriteReport', {embedded_relatorio: datasetId.reportId, jsonRecebido: reportFabric});
                    onRefreshUserPermissionsAccess();

                }
                if(datasetId.name === file.name.split('.pbix')[0] || datasetId.name === file.name.split('.PBIX')[0] && file) {
                    await onRefreshUserPermissionsAccess();
                    setReportData({
                        EmbeddedReport: datasetId.reportId,
                        paginado: reportData ? reportData.paginado : 0,
                        EmbeddedWorkspace: publishInWorkspace.trim() !== '' ? publishInWorkspace : info.workspace
                    })
                    return changeForAwaitForUser()
                } else {
                    Swal.fire({
                        title: "<strong>Não foi possível publicar</strong>",
                        icon: "info",
                        html:
                          "Falha ao publicar esse relatório..<br> " +
                          "Valide a integridade do arquivo .PBIX",
                        confirmButtonText:"<i class='fa fa-thumbs-up'></i> Fechar",
                        showCloseButton: true,
                        showCancelButton: false,
                        focusConfirm: false
                    })
                    await new Promise(resolve => setTimeout(resolve, 5000));
                    clearStates()
                    return
                }

            } catch (error) {
                Swal.fire({
                    title: "<strong>Não foi possível publicar</strong>",
                    icon: "info",
                    html:
                      "Falha ao publicar esse relatório...<br> " +
                      "Valide a integridade do arquivo .PBIX",
                    confirmButtonText:"<i class='fa fa-thumbs-up'></i> Fechar",
                    showCloseButton: true,
                    showCancelButton: false,
                    focusConfirm: false
                })
                await new Promise(resolve => setTimeout(resolve, 5000));
                clearStates()
                return console.log(error)
            }
        } else {
            return setTypeError(true)
        }
    }

    async function requestReportsInfo(name) {
        try {
            const response = await api.post('/reportsInfo-v2', {
                "nome": name
            })
            return response.data
        } catch (error) {
            return error
        }
    }

    async function saveReport(arquivopbix, businessArea, id){

        const formData = new FormData();
        formData.append('pbix', arquivopbix);
        formData.append('workspace', businessArea)
        formData.append('id_relatorio', id)

        try{
          const res = await api.post("reports/rollback/pub", formData)

          return res.data
        }catch(err){
            setConflictError(true)
            return err           
        }
    }

    async function updateReport(arquivopbix, businessArea, id){

        const formData = new FormData();

        formData.append('pbix', arquivopbix);
        formData.append('workspace', businessArea)
        formData.append('id_relatorio', id)

        try{
            const res = await api.post("reports/rollback/update", formData)
            return res.data
          }catch(err){
              setConflictError(true)
              return err           
          }
    }

    async function publishInFabric(workspace, token, nameConflict, filePbix, datasetDisplayName) {

        const importPBIXApi = `https://api.powerbi.com/v1.0/myorg/groups/${encodeURIComponent(workspace)}/imports?datasetDisplayName=${encodeURIComponent(datasetDisplayName)}&nameConflict=${encodeURIComponent(nameConflict)}&overrideModelLabel=false`;

        const formData = new FormData();
        formData.append('file', filePbix);

        const config = {
            headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data',
                },
        };

        try {
            const response = await axios.post(importPBIXApi, formData, config);
            return response.data
        } catch (err) {
            setConflictError(true)
            return err            
        }
    }

    async function requestDataSetID(workspace, reportId, token) {
        const config = {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'multipart/form-data',
            },
        };
    
        let datasetId = null;
        
        while (!datasetId) {
            try {
                const response = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${workspace}/imports/${reportId}`, config);
                if(response.data.importState === "Succeeded") {
                    const reports = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${workspace}/reports`, config);
                    const findReport = reports.data.value.find((report) => report.id == response.data.reports[0].id)
                    return datasetId = {reportId: response.data.reports[0].id, datasetId: findReport.datasetId, name: response.data.name}
                }
                
            } catch (error) {
                console.error("Erro na requisição:", error);
            }
    
            // Aguarde por um período antes da próxima tentativa (por exemplo, 1 segundo)
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    
        return datasetId;
    }
    

    async function handleSendToSquadBI(name, report, dataset, workspace, businessArea, reportFabric) {
        try {

            const response = await api.post('/reportsNew-v2', {
                nome: name,
                report: report, 
                dataset: dataset,
                workspace: workspace,
                businessArea: businessArea,
                jsonRecebido: reportFabric,
            })
            if(response.data.status) {
                return response.data
            } else {
                return {error: "Erro ao publicar relatório"}
            }
        } catch (error) {
            return error
        }
    }

    async function requestReport(idReport) {
        try {
            const res = await api.post("/reports", {id_relatorio: idReport, painel: 0})
            return res.data.relatorios[0]
        } catch (error) {
            return error
        }
    }

    // Export functions =========================
    async function handlePrepareExportFile(workspace, idReport, nomeRelatorio, format, withFilters, withHiddenPages, exportAllPages) {
        try {
            setExportingReport("awaiting server")
            const res = await api.post("/reports")
            const reportData = res.data.relatorios.filter((relatorio) => relatorio.embedded_relatorio == idReport)
            const reportDataIds = reportData[0].associated_paginated
            if(reportDataIds !== null && reportDataIds[0].trim() !== '') {
                const originalPromise = handleExportFile(workspace, idReport, nomeRelatorio, format, withFilters, undefined, withHiddenPages, exportAllPages);
                const additionalPromises = reportDataIds.map((itemId) =>
                    handleExportFile(workspace, itemId, nomeRelatorio, format, withFilters, true, false)
                );
                await Promise.all([originalPromise, ...additionalPromises]);
            } else {
                const originalPromise = handleExportFile(workspace, idReport, nomeRelatorio, format, withFilters,undefined, withHiddenPages, exportAllPages);
                await Promise.all([originalPromise]);
            }
            api.post('/createRefreshTokenLog', {message: 'Exportou relatório', report: idReport})
            setExportingReport('waiting for user')
        } catch (error) {
            console.error(error)
            api.post('/createRefreshTokenLog', {message: `Exportar - ${error.message}`, report: idReport})
            await new Promise(resolve => setTimeout(resolve, 5000));
            setExportingReport('indle')
        }
    }

    async function handleExportFile(workspace, idReport, nomeRelatorio, format, withFilters, paginated, withHiddenPages, exportAllPages) {
        try {
            const info = await requestReportsInfo(nomeRelatorio)
        if(info.message) {
            Swal.fire({
                title: "<strong>Não foi exportar seu arquivo.</strong>",
                icon: "info",
                html:
                  "Ocorreu uma falha ao exportar o arquivo<br> " +
                  "Tente novamente em alguns minutos..",
                confirmButtonText:"<i class='fa fa-thumbs-up'></i> Fechar",
                showCloseButton: true,
                showCancelButton: false,
                focusConfirm: false
            })
            await new Promise(resolve => setTimeout(resolve, 5000));
            setExportingReport('indle')
            return
        }
        const code = await requestExportCode(workspace, idReport, format, info.token, info.rls, withFilters, paginated, withHiddenPages, exportAllPages)
        if(code.message) {
            Swal.fire({
                title: "<strong>Não foi exportar seu arquivo.</strong>",
                icon: "info",
                html:
                  "Ocorreu uma falha ao exportar o arquivo<br> " +
                  "Tente novamente em alguns minutos..",
                confirmButtonText:"<i class='fa fa-thumbs-up'></i> Fechar",
                showCloseButton: true,
                showCancelButton: false,
                focusConfirm: false
            })
            await new Promise(resolve => setTimeout(resolve, 5000));
            setExportingReport('indle')
            return
        }
        const result = await requestExportFile(workspace, idReport, code, info.token)
        if(result.message) {
            Swal.fire({
                title: "<strong>Não foi exportar seu arquivo.</strong>",
                icon: "info",
                html:
                  "Ocorreu uma falha ao exportar o arquivo<br> " +
                  "Tente novamente em alguns minutos..",
                confirmButtonText:"<i class='fa fa-thumbs-up'></i> Fechar",
                showCloseButton: true,
                showCancelButton: false,
                focusConfirm: false
            })
            await new Promise(resolve => setTimeout(resolve, 5000));
            setExportingReport('indle')
            return
        }
            return
        } catch (error) {
            Swal.fire({
                title: "<strong>Não foi exportar seu arquivo.</strong>",
                icon: "info",
                html:
                  "Ocorreu uma falha ao exportar o arquivo<br> " +
                  "Tente novamente em alguns minutos..",
                confirmButtonText:"<i class='fa fa-thumbs-up'></i> Fechar",
                showCloseButton: true,
                showCancelButton: false,
                focusConfirm: false
            })
            await new Promise(resolve => setTimeout(resolve, 5000));
            setExportingReport('indle')
            return
        }
        
    }

    async function requestExportCode(workspace, idReport, format, token, rls, withFilters, paginated, withHiddenPages, exportAllPages) {

        const exportAPI = `https://api.powerbi.com/v1.0/myorg/groups/${workspace}/reports/${idReport}/ExportTo`
        
        const formData = new FormData();
        formData.append('format', format);

        const config = {
            headers: {
                    'Authorization': 'Bearer ' + token,
                },
        };

        let BodyJSON;
        if(paginated) {
            BodyJSON = {
                "format": format === 'PDF' ? 'ACCESSIBLEPDF' : format,
                
            };
        } else {
            const capturedBookmark = await window.report.bookmarksManager.capture({allPages: true, personalizeVisuals: true});
            const activePage = await window.report.getActivePage();
            const currentState = capturedBookmark.state
            if (rls === false) {
                if(withFilters !== '') {
                    BodyJSON = {
                        "format": format,
                        "powerBIReportConfiguration": {
                            "pages": exportAllPages ? [] : [ {"pageName": activePage.name}],
                            "defaultBookmark": {"state": withFilters === 'current' ? currentState : withFilters},
                            "settings": {"includeHiddenPages": withHiddenPages, "locale": "pt-br"}
                        }
                    };
                } else {
                    BodyJSON = {
                        "format": format,
                        "powerBIReportConfiguration": {
                            "pages": exportAllPages ? [] : [ {"pageName": activePage.name}],
                            "settings": {"includeHiddenPages": withHiddenPages, "locale": "pt-br"}
                        }
                    };
                }
            } else {
                if(withFilters !== '') {
                    let unicodeString = rls.rls_name;
                    let decodedString = decodeURIComponent(JSON.parse(`"${unicodeString}"`));
                    BodyJSON = {
                        "format": format,
                        "powerBIReportConfiguration": {
                            "identities": [
                                {
                                    "username": rls.rls_role,
                                    "roles": [decodedString],
                                    "datasets": [rls.dataset]
                                }
                            ],
                            "pages": exportAllPages ? [] : [ {"pageName": activePage.name}],
                            "defaultBookmark": {"state": withFilters === 'current' ? currentState : withFilters},
                            "settings": {"includeHiddenPages": withHiddenPages, "locale": "pt-br"}
                        }
                    };
                } else {
                    let unicodeString = rls.rls_name;
                    let decodedString = decodeURIComponent(JSON.parse(`"${unicodeString}"`));
                    BodyJSON = {
                        "format": format,
                        "powerBIReportConfiguration": {
                            "identities": [
                                {
                                    "username": rls.rls_role,
                                    "roles": [decodedString],
                                    "datasets": [rls.dataset]
                                }
                            ],
                            "pages": exportAllPages ? [] : [ {"pageName": activePage.name}],
                            "settings": {"includeHiddenPages": withHiddenPages, "locale": "pt-br"}
                        }
                    };
                }
            }
        }

        try {
            const response = await axios.post(exportAPI, BodyJSON, config);
            return response.data.id
        } catch (err) {
            setConflictError(true)
            return err            
        }
    }

    async function requestExportFile(workspace, idReport, code, token) {

        let fileComplete = false;
        let data;

        while (!fileComplete) {
            await new Promise(resolve => setTimeout(resolve, 5000));
            try {
                const response = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${workspace}/reports/${idReport}/exports/${code}`, {
                    headers: {
                    'Authorization': `Bearer ${token}`
                    }
                });

                data = response.data;

                if (data.status !== "Running") {
                    fileComplete = true;
                    break;
                }
            } catch (error) {
                console.log(error)
                console.error(error)
                return error
            }
        }

        try {
            const response = await axios.get(data.resourceLocation, {
            headers: {
                'Content-Type': 'text/xml',
                'Authorization': `Bearer ${token}`
            },
            responseType: 'arraybuffer'
            });

            setBlobFile(prevBlobFile => [
                ...prevBlobFile,
                { file: response.data, linkDownload: `${data.reportName}${data.resourceFileExtension}` }
            ]);
            return response;

        } catch (error) {
            console.error('Error fetching export file:', error);
        }
    }

    function downloadFile() {

        for (const blobInfo of blobFile) {
    
            const blob = new Blob([blobInfo.file], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
    
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
    
            link.href = url;
            link.download = blobInfo.linkDownload;
    
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    
            URL.revokeObjectURL(url);
        }
    
        setBlobFile([]);
        setExportingReport('indle');
    }

    // Export Report =========================
    async function handleExportReport(workspace, idReport, nomeRelatorio) {
        setDowloandReport("awaiting server")

        const info = await requestReportsInfo(nomeRelatorio)
        if(info.message) {
            Swal.fire({
                title: "<strong>Não foi exportar seu arquivo.</strong>",
                icon: "info",
                html:
                  "Ocorreu uma falha ao exportar o arquivo<br> " +
                  "Tente novamente em alguns minutos..",
                confirmButtonText:"<i class='fa fa-thumbs-up'></i> Fechar",
                showCloseButton: true,
                showCancelButton: false,
                focusConfirm: false
            })
            await new Promise(resolve => setTimeout(resolve, 5000));
            setDowloandReport('indle')
            return
        }
        const response = await requestExportReportInGroup(workspace, idReport, info.token, nomeRelatorio)
        if(response) {
            setDowloandReport('waiting for user')
        } else {
            Swal.fire({
                title: "<strong>Não foi exportar seu arquivo.</strong>",
                icon: "info",
                html:
                  "Ocorreu uma falha ao exportar o arquivo<br> " +
                  "Tente novamente em alguns minutos..",
                confirmButtonText:"<i class='fa fa-thumbs-up'></i> Fechar",
                showCloseButton: true,
                showCancelButton: false,
                focusConfirm: false
            })
            await new Promise(resolve => setTimeout(resolve, 5000));
            setDowloandReport('indle')
        }
    }

    async function requestExportReportInGroup(workspace, idReport, token, nomeRelatorio) {

        const exportAPI = `https://api.powerbi.com/v1.0/myorg/groups/${workspace}/reports/${idReport}/Export`
        

        const config = {
            headers: {
                    'Authorization': 'Bearer ' + token,
                },
            responseType: 'blob',
        };

        try {
            const response = await axios.get(exportAPI, config);
            setBlobPbix({file: response.data, linkDownload: `${nomeRelatorio}.pbix`})
            return true
        } catch (err) {
            setConflictError(true)
            return err            
        }
    }

    function pbixDownload() {
        const url = window.URL.createObjectURL(new Blob([blobPbix.file],{ type: 'application/octet-stream' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', blobPbix.linkDownload);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        setBlobPbix(null)
        setDowloandReport('indle')
        return
    }

    // Update Report ========================== 
    async function sendUpdateReport(idReport) {
        try {
            setUpdateReportID(idReport);
            setUpdatingReport('awaiting server');
            const response = await api.post('/refreshReport', { id_relatorio: idReport });
            if (response.data.status === true) {
                setUpdatingReport('success');
                setTimeout(() => {
                    setUpdatingReport('indle');
                    setUpdateReportID(null);
                }, 6000);
                return true;
            } else {
                setUpdatingReport("error");
                if(response.data && response.data.request && response.data.request.error.message === "Invalid dataset refresh request. Another refresh request is already executing") {
                    setDualUpdateError(true)
                }
                setTimeout(() => {
                    setUpdatingReport('indle');
                    setUpdateReportID(null);
                    setDualUpdateError(false)
                }, 10000);
                return false;
            }
        } catch (error) {
            setUpdatingReport("error");
            setTimeout(() => {
                setUpdatingReport('indle');
                setUpdateReportID(null);
            }, 3000);
            return false;
        }
    }    

    // send file to assistant
    async function sendFileToAssistant(id_assistant, id_dataset, id_dataset_workspace) {
        try {

            setAwaitAssistant(true)
            setAwaitAssistantStatus(0)

            const body = {
                id_assistant,
                datasetId: id_dataset,
                datasetWorkspaceId: id_dataset_workspace
            }

            const res = await api.post('/sendFileToAssistant', body)
            
            if(res.data.status == true) {
                setAwaitAssistantStatus(1)
            } else {
                setAwaitAssistantStatus(2)
            }

        } catch (error) {
            setAwaitAssistantStatus(2)
            console.error(error)
        }
    }

    function onCloseSubmitAssistant() {
        setAwaitAssistant(false)
        setAwaitAssistantStatus(1)
    }

    const valuesProvider = {
        statusSubmit,
        uploadReport,
        handleSumit,
        typeError,
        clearStates,
        exportingReport,
        handlePrepareExportFile,
        downloadFile,
        downloandReport,
        handleExportReport,
        requestReportsInfo,  // estou obtendo o token p/ req ao power bi
        sendUpdateReport,
        updatingReport,
        setSyncPowerBI,
        sendFileToAssistant,
        activeIA,
        setActiveIA
    };


    return (
        <ReportsContext.Provider 
            value={{...valuesProvider}}
        >
            <div
                style={{
                    position: 'fixed',
                    top: "45px",
                    right: "18px",
                    zIndex: 999999999,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px'
                }}  
                >

                {
                    (statusSubmit === "sending" || statusSubmit === "waiting for user") &&
                        <ShippingCard 
                            status={statusSubmit} 
                            idReport={statusSubmit === 'waiting for user' ? reportId : ''}
                            EmbeddedReport={statusSubmit === 'waiting for user' ? reportData ? reportData.EmbeddedReport : '' : ''}
                            EmbeddedWorkspace={statusSubmit === 'waiting for user' ? reportData ? reportData.EmbeddedWorkspace : '' : ''}
                            paginado={statusSubmit === 'waiting for user' ? reportData ? reportData.paginado : '' : 0}
                            conflictError={conflictError}
                        />
                }
                {exportingReport !== "indle" && <ShippingExportCard status={exportingReport} downloadFile={downloadFile} />}
                {awaitAssistant && <ShippingAssistantCard status={awaitAssistantStatus} onClose={onCloseSubmitAssistant} />}
                {downloandReport !== "indle" && <ShippingExportCard status={downloandReport} downloadFile={pbixDownload} pbix />}
                {updatingReport  !== "indle" && <ShippingUpdateCard status={updatingReport}  dualUpdateError={dualUpdateError} />}
                {syncPowerBI     !== 'indle' && <ModalSync />}
            </div>
            {(statusSubmit === "file selection" && typeSubmit === 'report') && <PublicarRelatorio />}
            {(statusSubmit === "file selection" && typeSubmit === 'update') &&  <AtualizarRelatorio />}
            {activeIA && <ModalSquadAI />}
            {children}
        </ReportsContext.Provider>
    )
}

export default ReportsContextProvider;