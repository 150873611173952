import { useTranslation } from 'react-i18next';

const usePowerBIData = () => {
  const { t } = useTranslation();

  const data = [
    [
      `1-${t('sidebar.powerBI.index')}`,
      "Windows"
    ],
    [
      {
          "id": "2222",
          "nome": `${t('sidebar.powerBI.embedded')}`,
          "rota": "/pages/admin/azureRegister",
          "icon": "mdi mdi-security"
      },
      {
          "id": "2222002",
          "nome": `${t('sidebar.powerBI.sync')}`,
          "rota": "/pages/admin/sync",
          "icon": "mdi mdi-security"
      },
      {
          "id": "222200202020",
          "nome": `${t('sidebar.powerBI.contracts')}`,
          "rota": "/pages/admin/contractActive",
          "icon": "mdi mdi-security"
      }
    ]
  ];

  return data;
};

export default usePowerBIData;